var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{staticClass:"pathModul",attrs:{"items":_vm.pathModul,"divider":"-","normal":""}}),_c('v-container',{staticClass:"container-filtering"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-input-filter"},[_c('v-select',{attrs:{"items":_vm.itemStation,"label":"Station"},on:{"change":_vm.selectValueStation}})],1),_c('v-col',{staticClass:"col-input-filter"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar),callback:function ($$v) {_vm.modalCalendar=$$v},expression:"modalCalendar"}},[_c('v-date-picker',{on:{"input":function($event){_vm.modalCalendar = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{staticClass:"col-input-filter"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar2),callback:function ($$v) {_vm.modalCalendar2=$$v},expression:"modalCalendar2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.modalCalendar2 = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{staticClass:"col-btn-generateReport",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-2 white--text btn-generate primary",attrs:{"solo":"","dense":"","normal":""},on:{"click":_vm.generateTable}},[_vm._v(" Generate Report "),_c('v-icon',{attrs:{"right":"","dark":"","big":""}},[_vm._v(" mdi-table-arrow-up ")])],1),_c('v-btn',{staticClass:"btnExportExcelRD",attrs:{"color":"green","elevation":"2"},on:{"click":function($event){return _vm.exportReport('excel')}}},[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1)],1)],1),_c('v-container',{staticClass:"container-result-generated"},[_c('v-subheader',{staticClass:"subTitle fontSubTitle-blue"},[_vm._v(" Raw Data For ("+_vm._s(_vm.valStation)+") From "+_vm._s(this.convertDateFormat(_vm.dateFrom))+" To "+_vm._s(this.convertDateFormat(_vm.dateTo))+" ")]),_c('div',[_c('v-data-table',{staticClass:"elevation-1 tableRawData headerDtSarawak",attrs:{"headers":_vm.thead_rawData,"items":_vm.tbody_rawData,"items-per-page":10,"fixed-header":"","loading":_vm.loadingTable,"loading-text":_vm.loadingText},scopedSlots:_vm._u([{key:"item.DATETIME",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.DATETIME)}})]}},{key:"item.WQI",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm.getFontColor(item.WQI),attrs:{"color":_vm.getColor(item.WQI),"dark":""}},[_vm._v(" "+_vm._s(item.WQI)+" ")])]}},{key:"item.DO_SAT",fn:function(ref){
var item = ref.item;
return [( item.DO_SAT_MIN == null && item.DO_SAT_MAX == null )?_c('span',[_vm._v(_vm._s(item.DO_SAT))]):( item.DO_SAT >= item.DO_SAT_MIN && item.DO_SAT <= item.DO_SAT_MAX )?_c('span',[_vm._v(_vm._s(item.DO_SAT))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.DO_SAT))])]}},{key:"item.DO_CON",fn:function(ref){
var item = ref.item;
return [( item.DO_CON_MIN == null && item.DO_CON_MAX == null )?_c('span',[_vm._v(_vm._s(item.DO_CON))]):( item.DO_CON >= item.DO_CON_MIN && item.DO_CON <= item.DO_CON_MAX )?_c('span',[_vm._v(_vm._s(item.DO_CON))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.DO_CON))])]}},{key:"item.BOD",fn:function(ref){
var item = ref.item;
return [( item.BOD_MIN == null && item.BOD_MAX == null )?_c('span',[_vm._v(_vm._s(item.BOD))]):( item.BOD >= item.BOD_MIN && item.BOD <= item.BOD_MAX )?_c('span',[_vm._v(_vm._s(item.BOD))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.BOD))])]}},{key:"item.COD",fn:function(ref){
var item = ref.item;
return [( item.COD_MIN == null && item.COD_MAX == null )?_c('span',[_vm._v(_vm._s(item.COD))]):( item.COD >= item.COD_MIN && item.COD <= item.COD_MAX )?_c('span',[_vm._v(_vm._s(item.COD))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.COD))])]}},{key:"item.NH3N",fn:function(ref){
var item = ref.item;
return [( item.NH3N_MIN == null && item.NH3N_MAX == null )?_c('span',[_vm._v(_vm._s(item.NH3N))]):( item.NH3N >= item.NH3N_MIN && item.NH3N <= item.NH3N_MAX )?_c('span',[_vm._v(_vm._s(item.NH3N))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.NH3N))])]}},{key:"item.TSS",fn:function(ref){
var item = ref.item;
return [( item.TSS_MIN == null && item.TSS_MAX == null )?_c('span',[_vm._v(_vm._s(item.TSS))]):( item.TSS >= item.TSS_MIN && item.TSS <= item.TSS_MAX )?_c('span',[_vm._v(_vm._s(item.TSS))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TSS))])]}},{key:"item.pH",fn:function(ref){
var item = ref.item;
return [( item.pH_MIN == null && item.pH_MAX == null )?_c('span',[_vm._v(_vm._s(item.pH))]):( item.pH >= item.pH_MIN && item.pH <= item.pH_MAX )?_c('span',[_vm._v(_vm._s(item.pH))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.pH))])]}},{key:"item._4_4_DDT",fn:function(ref){
var item = ref.item;
return [( item._4_4_DDT_MIN == null && item._4_4_DDT_MAX == null )?_c('span',[_vm._v(_vm._s(item._4_4_DDT))]):( item._4_4_DDT >= item._4_4_DDT_MIN && item._4_4_DDT <= item._4_4_DDT_MAX )?_c('span',[_vm._v(_vm._s(item._4_4_DDT))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item._4_4_DDT))])]}},{key:"item.AG",fn:function(ref){
var item = ref.item;
return [( item.AG_MIN == null && item.AG_MAX == null )?_c('span',[_vm._v(_vm._s(item.AG))]):( item.AG >= item.AG_MIN && item.AG <= item.AG_MAX )?_c('span',[_vm._v(_vm._s(item.AG))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.AG))])]}},{key:"item.AL",fn:function(ref){
var item = ref.item;
return [( item.AL_MIN == null && item.AL_MAX == null )?_c('span',[_vm._v(_vm._s(item.AL))]):( item.AL >= item.AL_MIN && item.AL <= item.AL_MAX )?_c('span',[_vm._v(_vm._s(item.AL))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.AL))])]}},{key:"item.ALDRIN_DIELDRIN",fn:function(ref){
var item = ref.item;
return [( item.ALDRIN_DIELDRIN_MIN == null && item.ALDRIN_DIELDRIN_MAX == null )?_c('span',[_vm._v(_vm._s(item.ALDRIN_DIELDRIN))]):( item.ALDRIN_DIELDRIN >= item.ALDRIN_DIELDRIN_MIN && item.ALDRIN_DIELDRIN <= item.ALDRIN_DIELDRIN_MAX )?_c('span',[_vm._v(_vm._s(item.ALDRIN_DIELDRIN))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.ALDRIN_DIELDRIN))])]}},{key:"item.AS",fn:function(ref){
var item = ref.item;
return [( item.AS_MIN == null && item.AS_MAX == null )?_c('span',[_vm._v(_vm._s(item.AS))]):( item.AS >= item.AS_MIN && item.AS <= item.AS_MAX )?_c('span',[_vm._v(_vm._s(item.AS))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.AS))])]}},{key:"item.B",fn:function(ref){
var item = ref.item;
return [( item.B_MIN == null && item.B_MAX == null )?_c('span',[_vm._v(_vm._s(item.B))]):( item.B >= item.B_MIN && item.B <= item.B_MAX )?_c('span',[_vm._v(_vm._s(item.B))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.B))])]}},{key:"item.BA",fn:function(ref){
var item = ref.item;
return [( item.BA_MIN == null && item.BA_MAX == null )?_c('span',[_vm._v(_vm._s(item.BA))]):( item.BA >= item.BA_MIN && item.BA <= item.BA_MAX )?_c('span',[_vm._v(_vm._s(item.BA))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.BA))])]}},{key:"item.BHC",fn:function(ref){
var item = ref.item;
return [( item.BHC_MIN == null && item.BHC_MAX == null )?_c('span',[_vm._v(_vm._s(item.BHC))]):( item.BHC >= item.BHC_MIN && item.BHC <= item.BHC_MAX )?_c('span',[_vm._v(_vm._s(item.BHC))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.BHC))])]}},{key:"item.BR",fn:function(ref){
var item = ref.item;
return [( item.BR_MIN == null && item.BR_MAX == null )?_c('span',[_vm._v(_vm._s(item.BR))]):( item.BR >= item.BR_MIN && item.BR <= item.BR_MAX )?_c('span',[_vm._v(_vm._s(item.BR))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.BR))])]}},{key:"item.CA",fn:function(ref){
var item = ref.item;
return [( item.CA_MIN == null && item.CA_MAX == null )?_c('span',[_vm._v(_vm._s(item.CA))]):( item.CA >= item.CA_MIN && item.CA <= item.CA_MAX )?_c('span',[_vm._v(_vm._s(item.CA))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CA))])]}},{key:"item.CCE",fn:function(ref){
var item = ref.item;
return [( item.CCE_MIN == null && item.CCE_MAX == null )?_c('span',[_vm._v(_vm._s(item.CCE))]):( item.CCE >= item.CCE_MIN && item.CCE <= item.CCE_MAX )?_c('span',[_vm._v(_vm._s(item.CCE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CCE))])]}},{key:"item.CD",fn:function(ref){
var item = ref.item;
return [( item.CD_MIN == null && item.CD_MAX == null )?_c('span',[_vm._v(_vm._s(item.CD))]):( item.CD >= item.CD_MIN && item.CD <= item.CD_MAX )?_c('span',[_vm._v(_vm._s(item.CD))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CD))])]}},{key:"item.CDOM",fn:function(ref){
var item = ref.item;
return [( item.CDOM_MIN == null && item.CDOM_MAX == null )?_c('span',[_vm._v(_vm._s(item.CDOM))]):( item.CDOM >= item.CDOM_MIN && item.CDOM <= item.CDOM_MAX )?_c('span',[_vm._v(_vm._s(item.CDOM))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CDOM))])]}},{key:"item.CHLORDANE",fn:function(ref){
var item = ref.item;
return [( item.CHLORDANE_MIN == null && item.CHLORDANE_MAX == null )?_c('span',[_vm._v(_vm._s(item.CHLORDANE))]):( item.CHLORDANE >= item.CHLORDANE_MIN && item.CHLORDANE <= item.CHLORDANE_MAX )?_c('span',[_vm._v(_vm._s(item.CHLORDANE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CHLORDANE))])]}},{key:"item.CHLOROPHYLL_A",fn:function(ref){
var item = ref.item;
return [( item.CHLOROPHYLL_A_MIN == null && item.CHLOROPHYLL_A_MAX == null )?_c('span',[_vm._v(_vm._s(item.CHLOROPHYLL_A))]):( item.CHLOROPHYLL_A >= item.CHLOROPHYLL_A_MIN && item.CHLOROPHYLL_A <= item.CHLOROPHYLL_A_MAX )?_c('span',[_vm._v(_vm._s(item.CHLOROPHYLL_A))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CHLOROPHYLL_A))])]}},{key:"item.CL",fn:function(ref){
var item = ref.item;
return [( item.CL_MIN == null && item.CL_MAX == null )?_c('span',[_vm._v(_vm._s(item.CL))]):( item.CL >= item.CL_MIN && item.CL <= item.CL_MAX )?_c('span',[_vm._v(_vm._s(item.CL))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CL))])]}},{key:"item.CL2",fn:function(ref){
var item = ref.item;
return [( item.CL2_MIN == null && item.CL2_MAX == null )?_c('span',[_vm._v(_vm._s(item.CL2))]):( item.CL2 >= item.CL2_MIN && item.CL2 <= item.CL2_MAX )?_c('span',[_vm._v(_vm._s(item.CL2))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CL2))])]}},{key:"item.CN",fn:function(ref){
var item = ref.item;
return [( item.CN_MIN == null && item.CN_MAX == null )?_c('span',[_vm._v(_vm._s(item.CN))]):( item.CN >= item.CN_MIN && item.CN <= item.CN_MAX )?_c('span',[_vm._v(_vm._s(item.CN))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CN))])]}},{key:"item.CO2",fn:function(ref){
var item = ref.item;
return [( item.CO2_MIN == null && item.CO2_MAX == null )?_c('span',[_vm._v(_vm._s(item.CO2))]):( item.CO2 >= item.CO2_MIN && item.CO2 <= item.CO2_MAX )?_c('span',[_vm._v(_vm._s(item.CO2))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CO2))])]}},{key:"item.COLOUR",fn:function(ref){
var item = ref.item;
return [( item.COLOUR_MIN == null && item.COLOUR_MAX == null )?_c('span',[_vm._v(_vm._s(item.COLOUR))]):( item.COLOUR >= item.COLOUR_MIN && item.COLOUR <= item.COLOUR_MAX )?_c('span',[_vm._v(_vm._s(item.COLOUR))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.COLOUR))])]}},{key:"item.CR",fn:function(ref){
var item = ref.item;
return [( item.CR_MIN == null && item.CR_MAX == null )?_c('span',[_vm._v(_vm._s(item.CR))]):( item.CR >= item.CR_MIN && item.CR <= item.CR_MAX )?_c('span',[_vm._v(_vm._s(item.CR))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CR))])]}},{key:"item.CR_III",fn:function(ref){
var item = ref.item;
return [( item.CR_III_MIN == null && item.CR_III_MAX == null )?_c('span',[_vm._v(_vm._s(item.CR_III))]):( item.CR_III >= item.CR_III_MIN && item.CR_III <= item.CR_III_MAX )?_c('span',[_vm._v(_vm._s(item.CR_III))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CR_III))])]}},{key:"item.CR_IV",fn:function(ref){
var item = ref.item;
return [( item.CR_IV_MIN == null && item.CR_IV_MAX == null )?_c('span',[_vm._v(_vm._s(item.CR_IV))]):( item.CR_IV >= item.CR_IV_MIN && item.CR_IV <= item.CR_IV_MAX )?_c('span',[_vm._v(_vm._s(item.CR_IV))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CR_IV))])]}},{key:"item.CU",fn:function(ref){
var item = ref.item;
return [( item.CU_MIN == null && item.CU_MAX == null )?_c('span',[_vm._v(_vm._s(item.CU))]):( item.CU >= item.CU_MIN && item.CU <= item.CU_MAX )?_c('span',[_vm._v(_vm._s(item.CU))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.CU))])]}},{key:"item.DEPTH",fn:function(ref){
var item = ref.item;
return [( item.DEPTH_MIN == null && item.DEPTH_MAX == null )?_c('span',[_vm._v(_vm._s(item.DEPTH))]):( item.DEPTH >= item.DEPTH_MIN && item.DEPTH <= item.DEPTH_MAX )?_c('span',[_vm._v(_vm._s(item.DEPTH))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.DEPTH))])]}},{key:"item.DOC",fn:function(ref){
var item = ref.item;
return [( item.DOC_MIN == null && item.DOC_MAX == null )?_c('span',[_vm._v(_vm._s(item.DOC))]):( item.DOC >= item.DOC_MIN && item.DOC <= item.DOC_MAX )?_c('span',[_vm._v(_vm._s(item.DOC))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.DOC))])]}},{key:"item.E_COLI",fn:function(ref){
var item = ref.item;
return [( item.E_COLI_MIN == null && item.E_COLI_MAX == null )?_c('span',[_vm._v(_vm._s(item.E_COLI))]):( item.E_COLI >= item.E_COLI_MIN && item.E_COLI <= item.E_COLI_MAX )?_c('span',[_vm._v(_vm._s(item.E_COLI))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.E_COLI))])]}},{key:"item.EC",fn:function(ref){
var item = ref.item;
return [( item.EC_MIN == null && item.EC_MAX == null )?_c('span',[_vm._v(_vm._s(item.EC))]):( item.EC >= item.EC_MIN && item.EC <= item.EC_MAX )?_c('span',[_vm._v(_vm._s(item.EC))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.EC))])]}},{key:"item.ENDOSULFAN",fn:function(ref){
var item = ref.item;
return [( item.ENDOSULFAN_MIN == null && item.ENDOSULFAN_MAX == null )?_c('span',[_vm._v(_vm._s(item.ENDOSULFAN))]):( item.ENDOSULFAN >= item.ENDOSULFAN_MIN && item.ENDOSULFAN <= item.ENDOSULFAN_MAX )?_c('span',[_vm._v(_vm._s(item.ENDOSULFAN))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.ENDOSULFAN))])]}},{key:"item.F",fn:function(ref){
var item = ref.item;
return [( item.F_MIN == null && item.F_MAX == null )?_c('span',[_vm._v(_vm._s(item.F))]):( item.F >= item.F_MIN && item.F <= item.F_MAX )?_c('span',[_vm._v(_vm._s(item.F))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.F))])]}},{key:"item.FC",fn:function(ref){
var item = ref.item;
return [( item.FC_MIN == null && item.FC_MAX == null )?_c('span',[_vm._v(_vm._s(item.FC))]):( item.FC >= item.FC_MIN && item.FC <= item.FC_MAX )?_c('span',[_vm._v(_vm._s(item.FC))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.FC))])]}},{key:"item.FDOM",fn:function(ref){
var item = ref.item;
return [( item.FDOM_MIN == null && item.FDOM_MAX == null )?_c('span',[_vm._v(_vm._s(item.FDOM))]):( item.FDOM >= item.FDOM_MIN && item.FDOM <= item.FDOM_MAX )?_c('span',[_vm._v(_vm._s(item.FDOM))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.FDOM))])]}},{key:"item.FE",fn:function(ref){
var item = ref.item;
return [( item.FE_MIN == null && item.FE_MAX == null )?_c('span',[_vm._v(_vm._s(item.FE))]):( item.FE >= item.FE_MIN && item.FE <= item.FE_MAX )?_c('span',[_vm._v(_vm._s(item.FE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.FE))])]}},{key:"item.FLOWRATE",fn:function(ref){
var item = ref.item;
return [( item.FLOWRATE_MIN == null && item.FLOWRATE_MAX == null )?_c('span',[_vm._v(_vm._s(item.FLOWRATE))]):( item.FLOWRATE >= item.FLOWRATE_MIN && item.FLOWRATE <= item.FLOWRATE_MAX )?_c('span',[_vm._v(_vm._s(item.FLOWRATE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.FLOWRATE))])]}},{key:"item.GROSS_ALPHA",fn:function(ref){
var item = ref.item;
return [( item.GROSS_ALPHA_MIN == null && item.GROSS_ALPHA_MAX == null )?_c('span',[_vm._v(_vm._s(item.GROSS_ALPHA))]):( item.GROSS_ALPHA >= item.GROSS_ALPHA_MIN && item.GROSS_ALPHA <= item.GROSS_ALPHA_MAX )?_c('span',[_vm._v(_vm._s(item.GROSS_ALPHA))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.GROSS_ALPHA))])]}},{key:"item.GROSS_BETA",fn:function(ref){
var item = ref.item;
return [( item.GROSS_BETA_MIN == null && item.GROSS_BETA_MAX == null )?_c('span',[_vm._v(_vm._s(item.GROSS_BETA))]):( item.GROSS_BETA >= item.GROSS_BETA_MIN && item.GROSS_BETA <= item.GROSS_BETA_MAX )?_c('span',[_vm._v(_vm._s(item.GROSS_BETA))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.GROSS_BETA))])]}},{key:"item.HARDNESS",fn:function(ref){
var item = ref.item;
return [( item.HARDNESS_MIN == null && item.HARDNESS_MAX == null )?_c('span',[_vm._v(_vm._s(item.HARDNESS))]):( item.HARDNESS >= item.HARDNESS_MIN && item.HARDNESS <= item.HARDNESS_MAX )?_c('span',[_vm._v(_vm._s(item.HARDNESS))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.HARDNESS))])]}},{key:"item.HEDONAL_2_4_D",fn:function(ref){
var item = ref.item;
return [( item.HEDONAL_2_4_D_MIN == null && item.HEDONAL_2_4_D_MAX == null )?_c('span',[_vm._v(_vm._s(item.HEDONAL_2_4_D))]):( item.HEDONAL_2_4_D >= item.HEDONAL_2_4_D_MIN && item.HEDONAL_2_4_D <= item.HEDONAL_2_4_D_MAX )?_c('span',[_vm._v(_vm._s(item.HEDONAL_2_4_D))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.HEDONAL_2_4_D))])]}},{key:"item.HEPTACHLOR_EPOXIDE",fn:function(ref){
var item = ref.item;
return [( item.HEPTACHLOR_EPOXIDE_MIN == null && item.HEPTACHLOR_EPOXIDE_MAX == null )?_c('span',[_vm._v(_vm._s(item.HEPTACHLOR_EPOXIDE))]):( item.HEPTACHLOR_EPOXIDE >= item.HEPTACHLOR_EPOXIDE_MIN && item.HEPTACHLOR_EPOXIDE <= item.HEPTACHLOR_EPOXIDE_MAX )?_c('span',[_vm._v(_vm._s(item.HEPTACHLOR_EPOXIDE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.HEPTACHLOR_EPOXIDE))])]}},{key:"item.HG",fn:function(ref){
var item = ref.item;
return [( item.HG_MIN == null && item.HG_MAX == null )?_c('span',[_vm._v(_vm._s(item.HG))]):( item.HG >= item.HG_MIN && item.HG <= item.HG_MAX )?_c('span',[_vm._v(_vm._s(item.HG))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.HG))])]}},{key:"item.K",fn:function(ref){
var item = ref.item;
return [( item.K_MIN == null && item.K_MAX == null )?_c('span',[_vm._v(_vm._s(item.K))]):( item.K >= item.K_MIN && item.K <= item.K_MAX )?_c('span',[_vm._v(_vm._s(item.K))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.K))])]}},{key:"item.LINDANE",fn:function(ref){
var item = ref.item;
return [( item.LINDANE_MIN == null && item.LINDANE_MAX == null )?_c('span',[_vm._v(_vm._s(item.LINDANE))]):( item.LINDANE >= item.LINDANE_MIN && item.LINDANE <= item.LINDANE_MAX )?_c('span',[_vm._v(_vm._s(item.LINDANE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.LINDANE))])]}},{key:"item.MBAS",fn:function(ref){
var item = ref.item;
return [( item.MBAS_MIN == null && item.MBAS_MAX == null )?_c('span',[_vm._v(_vm._s(item.MBAS))]):( item.MBAS >= item.MBAS_MIN && item.MBAS <= item.MBAS_MAX )?_c('span',[_vm._v(_vm._s(item.MBAS))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.MBAS))])]}},{key:"item.MG",fn:function(ref){
var item = ref.item;
return [( item.MG_MIN == null && item.MG_MAX == null )?_c('span',[_vm._v(_vm._s(item.MG))]):( item.MG >= item.MG_MIN && item.MG <= item.MG_MAX )?_c('span',[_vm._v(_vm._s(item.MG))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.MG))])]}},{key:"item.MN",fn:function(ref){
var item = ref.item;
return [( item.MN_MIN == null && item.MN_MAX == null )?_c('span',[_vm._v(_vm._s(item.MN))]):( item.MN >= item.MN_MIN && item.MN <= item.MN_MAX )?_c('span',[_vm._v(_vm._s(item.MN))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.MN))])]}},{key:"item.NA",fn:function(ref){
var item = ref.item;
return [( item.NA_MIN == null && item.NA_MAX == null )?_c('span',[_vm._v(_vm._s(item.NA))]):( item.NA >= item.NA_MIN && item.NA <= item.NA_MAX )?_c('span',[_vm._v(_vm._s(item.NA))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.NA))])]}},{key:"item.NH4",fn:function(ref){
var item = ref.item;
return [( item.NH4_MIN == null && item.NH4_MAX == null )?_c('span',[_vm._v(_vm._s(item.NH4))]):( item.NH4 >= item.NH4_MIN && item.NH4 <= item.NH4_MAX )?_c('span',[_vm._v(_vm._s(item.NH4))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.NH4))])]}},{key:"item.NI",fn:function(ref){
var item = ref.item;
return [( item.NI_MIN == null && item.NI_MAX == null )?_c('span',[_vm._v(_vm._s(item.NI))]):( item.NI >= item.NI_MIN && item.NI <= item.NI_MAX )?_c('span',[_vm._v(_vm._s(item.NI))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.NI))])]}},{key:"item.NO2",fn:function(ref){
var item = ref.item;
return [( item.NO2_MIN == null && item.NO2_MAX == null )?_c('span',[_vm._v(_vm._s(item.NO2))]):( item.NO2 >= item.NO2_MIN && item.NO2 <= item.NO2_MAX )?_c('span',[_vm._v(_vm._s(item.NO2))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.NO2))])]}},{key:"item.NO3",fn:function(ref){
var item = ref.item;
return [( item.NO3_MIN == null && item.NO3_MAX == null )?_c('span',[_vm._v(_vm._s(item.NO3))]):( item.NO3 >= item.NO3_MIN && item.NO3 <= item.NO3_MAX )?_c('span',[_vm._v(_vm._s(item.NO3))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.NO3))])]}},{key:"item.OG_EDIBLE",fn:function(ref){
var item = ref.item;
return [( item.OG_EDIBLE_MIN == null && item.OG_EDIBLE_MAX == null )?_c('span',[_vm._v(_vm._s(item.OG_EDIBLE))]):( item.OG_EDIBLE >= item.OG_EDIBLE_MIN && item.OG_EDIBLE <= item.OG_EDIBLE_MAX )?_c('span',[_vm._v(_vm._s(item.OG_EDIBLE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.OG_EDIBLE))])]}},{key:"item.OG_MINERAL",fn:function(ref){
var item = ref.item;
return [( item.OG_MINERAL_MIN == null && item.OG_MINERAL_MAX == null )?_c('span',[_vm._v(_vm._s(item.OG_MINERAL))]):( item.OG_MINERAL >= item.OG_MINERAL_MIN && item.OG_MINERAL <= item.OG_MINERAL_MAX )?_c('span',[_vm._v(_vm._s(item.OG_MINERAL))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.OG_MINERAL))])]}},{key:"item.P",fn:function(ref){
var item = ref.item;
return [( item.P_MIN == null && item.P_MAX == null )?_c('span',[_vm._v(_vm._s(item.P))]):( item.P >= item.P_MIN && item.P <= item.P_MAX )?_c('span',[_vm._v(_vm._s(item.P))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.P))])]}},{key:"item.PARAQUAT",fn:function(ref){
var item = ref.item;
return [( item.PARAQUAT_MIN == null && item.PARAQUAT_MAX == null )?_c('span',[_vm._v(_vm._s(item.PARAQUAT))]):( item.PARAQUAT >= item.PARAQUAT_MIN && item.PARAQUAT <= item.PARAQUAT_MAX )?_c('span',[_vm._v(_vm._s(item.PARAQUAT))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.PARAQUAT))])]}},{key:"item.PB",fn:function(ref){
var item = ref.item;
return [( item.PB_MIN == null && item.PB_MAX == null )?_c('span',[_vm._v(_vm._s(item.PB))]):( item.PB >= item.PB_MIN && item.PB <= item.PB_MAX )?_c('span',[_vm._v(_vm._s(item.PB))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.PB))])]}},{key:"item.PCB",fn:function(ref){
var item = ref.item;
return [( item.PCB_MIN == null && item.PCB_MAX == null )?_c('span',[_vm._v(_vm._s(item.PCB))]):( item.PCB >= item.PCB_MIN && item.PCB <= item.PCB_MAX )?_c('span',[_vm._v(_vm._s(item.PCB))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.PCB))])]}},{key:"item.PHENOL",fn:function(ref){
var item = ref.item;
return [( item.PHENOL_MIN == null && item.PHENOL_MAX == null )?_c('span',[_vm._v(_vm._s(item.PHENOL))]):( item.PHENOL >= item.PHENOL_MIN && item.PHENOL <= item.PHENOL_MAX )?_c('span',[_vm._v(_vm._s(item.PHENOL))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.PHENOL))])]}},{key:"item.PO4_3",fn:function(ref){
var item = ref.item;
return [( item.PO4_3_MIN == null && item.PO4_3_MAX == null )?_c('span',[_vm._v(_vm._s(item.PO4_3))]):( item.PO4_3 >= item.PO4_3_MIN && item.PO4_3 <= item.PO4_3_MAX )?_c('span',[_vm._v(_vm._s(item.PO4_3))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.PO4_3))])]}},{key:"item.RA226",fn:function(ref){
var item = ref.item;
return [( item.RA226_MIN == null && item.RA226_MAX == null )?_c('span',[_vm._v(_vm._s(item.RA226))]):( item.RA226 >= item.RA226_MIN && item.RA226 <= item.RA226_MAX )?_c('span',[_vm._v(_vm._s(item.RA226))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.RA226))])]}},{key:"item.S",fn:function(ref){
var item = ref.item;
return [( item.S_MIN == null && item.S_MAX == null )?_c('span',[_vm._v(_vm._s(item.S))]):( item.S >= item.S_MIN && item.S <= item.S_MAX )?_c('span',[_vm._v(_vm._s(item.S))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.S))])]}},{key:"item.SALINITY",fn:function(ref){
var item = ref.item;
return [( item.SALINITY_MIN == null && item.SALINITY_MAX == null )?_c('span',[_vm._v(_vm._s(item.SALINITY))]):( item.SALINITY >= item.SALINITY_MIN && item.SALINITY <= item.SALINITY_MAX )?_c('span',[_vm._v(_vm._s(item.SALINITY))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SALINITY))])]}},{key:"item.SE",fn:function(ref){
var item = ref.item;
return [( item.SE_MIN == null && item.SE_MAX == null )?_c('span',[_vm._v(_vm._s(item.SE))]):( item.SE >= item.SE_MIN && item.SE <= item.SE_MAX )?_c('span',[_vm._v(_vm._s(item.SE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SE))])]}},{key:"item.SILICA",fn:function(ref){
var item = ref.item;
return [( item.SILICA_MIN == null && item.SILICA_MAX == null )?_c('span',[_vm._v(_vm._s(item.SILICA))]):( item.SILICA >= item.SILICA_MIN && item.SILICA <= item.SILICA_MAX )?_c('span',[_vm._v(_vm._s(item.SILICA))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SILICA))])]}},{key:"item.SILVEX_2_4_5_TP",fn:function(ref){
var item = ref.item;
return [( item.SILVEX_2_4_5_TP_MIN == null && item.SILVEX_2_4_5_TP_MAX == null )?_c('span',[_vm._v(_vm._s(item.SILVEX_2_4_5_TP))]):( item.SILVEX_2_4_5_TP >= item.SILVEX_2_4_5_TP_MIN && item.SILVEX_2_4_5_TP <= item.SILVEX_2_4_5_TP_MAX )?_c('span',[_vm._v(_vm._s(item.SILVEX_2_4_5_TP))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SILVEX_2_4_5_TP))])]}},{key:"item.SN",fn:function(ref){
var item = ref.item;
return [( item.SN_MIN == null && item.SN_MAX == null )?_c('span',[_vm._v(_vm._s(item.SN))]):( item.SN >= item.SN_MIN && item.SN <= item.SN_MAX )?_c('span',[_vm._v(_vm._s(item.SN))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SN))])]}},{key:"item.SO4",fn:function(ref){
var item = ref.item;
return [( item.SO4_MIN == null && item.SO4_MAX == null )?_c('span',[_vm._v(_vm._s(item.SO4))]):( item.SO4 >= item.SO4_MIN && item.SO4 <= item.SO4_MAX )?_c('span',[_vm._v(_vm._s(item.SO4))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SO4))])]}},{key:"item.SOLAR_BATTERY",fn:function(ref){
var item = ref.item;
return [( item.SOLAR_BATTERY_MIN == null && item.SOLAR_BATTERY_MAX == null )?_c('span',[_vm._v(_vm._s(item.SOLAR_BATTERY))]):( item.SOLAR_BATTERY >= item.SOLAR_BATTERY_MIN && item.SOLAR_BATTERY <= item.SOLAR_BATTERY_MAX )?_c('span',[_vm._v(_vm._s(item.SOLAR_BATTERY))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SOLAR_BATTERY))])]}},{key:"item.SR_90",fn:function(ref){
var item = ref.item;
return [( item.SR_90_MIN == null && item.SR_90_MAX == null )?_c('span',[_vm._v(_vm._s(item.SR_90))]):( item.SR_90 >= item.SR_90_MIN && item.SR_90 <= item.SR_90_MAX )?_c('span',[_vm._v(_vm._s(item.SR_90))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.SR_90))])]}},{key:"item.TC",fn:function(ref){
var item = ref.item;
return [( item.TC_MIN == null && item.TC_MAX == null )?_c('span',[_vm._v(_vm._s(item.TC))]):( item.TC >= item.TC_MIN && item.TC <= item.TC_MAX )?_c('span',[_vm._v(_vm._s(item.TC))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TC))])]}},{key:"item.TDS",fn:function(ref){
var item = ref.item;
return [( item.TDS_MIN == null && item.TDS_MAX == null )?_c('span',[_vm._v(_vm._s(item.TDS))]):( item.TDS >= item.TDS_MIN && item.TDS <= item.TDS_MAX )?_c('span',[_vm._v(_vm._s(item.TDS))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TDS))])]}},{key:"item.TEMPERATURE",fn:function(ref){
var item = ref.item;
return [( item.TEMPERATURE_MIN == null && item.TEMPERATURE_MAX == null )?_c('span',[_vm._v(_vm._s(item.TEMPERATURE))]):( item.TEMPERATURE >= item.TEMPERATURE_MIN && item.TEMPERATURE <= item.TEMPERATURE_MAX )?_c('span',[_vm._v(_vm._s(item.TEMPERATURE))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TEMPERATURE))])]}},{key:"item.TOC",fn:function(ref){
var item = ref.item;
return [( item.TOC_MIN == null && item.TOC_MAX == null )?_c('span',[_vm._v(_vm._s(item.TOC))]):( item.TOC >= item.TOC_MIN && item.TOC <= item.TOC_MAX )?_c('span',[_vm._v(_vm._s(item.TOC))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TOC))])]}},{key:"item.TRIOXONE_2_4_5_T",fn:function(ref){
var item = ref.item;
return [( item.TRIOXONE_2_4_5_T_MIN == null && item.TRIOXONE_2_4_5_T_MAX == null )?_c('span',[_vm._v(_vm._s(item.TRIOXONE_2_4_5_T))]):( item.TRIOXONE_2_4_5_T >= item.TRIOXONE_2_4_5_T_MIN && item.TRIOXONE_2_4_5_T <= item.TRIOXONE_2_4_5_T_MAX )?_c('span',[_vm._v(_vm._s(item.TRIOXONE_2_4_5_T))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TRIOXONE_2_4_5_T))])]}},{key:"item.TURBIDITY",fn:function(ref){
var item = ref.item;
return [( item.TURBIDITY_MIN == null && item.TURBIDITY_MAX == null )?_c('span',[_vm._v(_vm._s(item.TURBIDITY))]):( item.TURBIDITY >= item.TURBIDITY_MIN && item.TURBIDITY <= item.TURBIDITY_MAX )?_c('span',[_vm._v(_vm._s(item.TURBIDITY))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TURBIDITY))])]}},{key:"item.U",fn:function(ref){
var item = ref.item;
return [( item.U_MIN == null && item.U_MAX == null )?_c('span',[_vm._v(_vm._s(item.U))]):( item.U >= item.U_MIN && item.U <= item.U_MAX )?_c('span',[_vm._v(_vm._s(item.U))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.U))])]}},{key:"item.VELOCITY",fn:function(ref){
var item = ref.item;
return [( item.VELOCITY_MIN == null && item.VELOCITY_MAX == null )?_c('span',[_vm._v(_vm._s(item.VELOCITY))]):( item.VELOCITY >= item.VELOCITY_MIN && item.VELOCITY <= item.VELOCITY_MAX )?_c('span',[_vm._v(_vm._s(item.VELOCITY))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.VELOCITY))])]}},{key:"item.ZN",fn:function(ref){
var item = ref.item;
return [( item.ZN_MIN == null && item.ZN_MAX == null )?_c('span',[_vm._v(_vm._s(item.ZN))]):( item.ZN >= item.ZN_MIN && item.ZN <= item.ZN_MAX )?_c('span',[_vm._v(_vm._s(item.ZN))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.ZN))])]}},{key:"item.TRYPHPTOPHAN",fn:function(ref){
var item = ref.item;
return [( item.TRYPHPTOPHAN_MIN == null && item.TRYPHPTOPHAN_MAX == null )?_c('span',[_vm._v(_vm._s(item.TRYPHPTOPHAN))]):( item.TRYPHPTOPHAN >= item.TRYPHPTOPHAN_MIN && item.ZN <= item.TRYPHPTOPHAN_MAX )?_c('span',[_vm._v(_vm._s(item.TRYPHPTOPHAN))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.TRYPHPTOPHAN))])]}}],null,true)}),_c('p',{staticStyle:{"font-size":"0.90em","text-align":"left"}},[_c('b',[_vm._v(" Note: Red text indicated that value has exceeded above maximum & minimum value ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }